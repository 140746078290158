import { ThunkAction } from 'redux-thunk';
import { ICardPurchaseData } from '../../components/Checkout/Card/Details';
import { IApiErrorResponse } from '../../models/Api';
import { ICardTypeAvailable, IPhoneCardPurchase } from '../../models/PhoneCard';
import { IAppState } from '../../reducers';
import * as phoneCardResource from '../../resources/PhoneCard';
import * as phoneCardPurchaseResource from '../../resources/PhoneCardPurchase';
import { ICardPurchaseRequest } from '../../resources/PhoneCardPurchase';
import Sentry from '../../services/sentry';
import { IProfileUpdateSuccessAction, PROFILE_UPDATE_SUCCESS } from '../online-user';

export const SET_DETAILS = 'CHECKOUT/CARD_SET_DETAILS';
interface ISetDetailsAction {
  type: typeof SET_DETAILS
  details: ICardPurchaseData
}
export function setDetails(details: ICardPurchaseData): ISetDetailsAction {
  if (details.inmate == null) {
    details.inmate = {
      firstName: details.inmateInformation.firstName,
      lastName: details.inmateInformation.lastName,
      account: null
    }
  }
  return { type: SET_DETAILS, details };
}

export const PURCHASE_PROCESSING = 'CHECKOUT/CARD_PURCHASE_PROCESSING';
export const PURCHASE_SUCCESS = 'CHECKOUT/CARD_PURCHASE_SUCCESS';
export const PURCHASE_FAILURE = 'CHECKOUT/CARD_PURCHASE_FAILURE';
interface IPurchaseProcessingAction {
  type: typeof PURCHASE_PROCESSING
  payment: ICardPurchaseRequest
}
interface IPurchaseSuccessAction {
  type: typeof PURCHASE_SUCCESS
  purchaseData: ICardPurchaseRequest
  purchase: IPhoneCardPurchase
}
interface IPurchaseFailureAction {
  type: typeof PURCHASE_FAILURE
  error: Error|IApiErrorResponse
}
type PurchaseActions = IPurchaseProcessingAction | IPurchaseSuccessAction | IPurchaseFailureAction | IProfileUpdateSuccessAction;
export function processPurchase(payment: ICardPurchaseRequest): ThunkAction<Promise<IPhoneCardPurchase>, IAppState, null, PurchaseActions> {
  return dispatch => {
    dispatch({ type: PURCHASE_PROCESSING, payment });

    if (payment.newCreditCard) {
      const expParts = payment.newCreditCard.expirationDate.split('/');
      const expMonth = expParts[0];
      const expYear = expParts[1];
      payment.newCreditCard.expMonth = expMonth;
      payment.newCreditCard.expYear = expYear.substr(-2);
    }

    // Remove any expected spaces or dashes in the card number
    if (payment.newCreditCard && payment.newCreditCard.cardNumber) {
      payment.newCreditCard.cardNumber = payment.newCreditCard.cardNumber.replace(/[^0-9]/g, '');
    }

    return phoneCardPurchaseResource
      .post(payment)
      .then(res => {
        if (res == null || !res.success) {
          throw new Error('Invalid response from server');
        }

        // Update the in-memory profile data with the personal information supplied
        dispatch({ type: PROFILE_UPDATE_SUCCESS, user: {
          firstName: payment.purchaserDetails.firstName,
          lastName: payment.purchaserDetails.lastName,
          emailAddress: payment.purchaserDetails.emailAddress,
          address: payment.purchaserDetails.address,
          city: payment.purchaserDetails.city,
          state: payment.purchaserDetails.state,
          zip: payment.purchaserDetails.zip,
          phoneNumber: payment.purchaserDetails.phoneNumber
        }});

        dispatch({ type: PURCHASE_SUCCESS, purchase: res.order, purchaseData: payment });
        return res.order;
      })
      .catch(error => {
        Sentry.captureException(error);
        dispatch({ type: PURCHASE_FAILURE, error });
        throw error;
      });
  }
}

export const RESET = 'CHECKOUT/RESET';
interface IResetAction {
  type: typeof RESET
}
export function reset(): ThunkAction<void, IAppState, undefined, IResetAction | IResetSensitiveAction> {
  return (dispatch, getState) => {
    const state: IAppState = getState();

    if (state.checkout.card.purchase) {
      dispatch({ type: RESET });
    } else {
      dispatch(resetSensitive());
    }
  }
}

export const RESET_SENSITIVE = 'CHECKOUT/CARD_RESET_SENSITIVE';
interface IResetSensitiveAction {
  type: typeof RESET_SENSITIVE
}
export function resetSensitive(): IResetSensitiveAction {
  return { type: RESET_SENSITIVE };
}

export const CARD_TYPES_LOADING = 'CHECKOUT/CARD_TYPES_LOADING';
export const CARD_TYPES_LOAD_SUCCESS = 'CHECKOUT/CARD_TYPES_LOAD_SUCCESS';
export const CARD_TYPES_LOAD_FAILURE = 'CHECKOUT/CARD_TYPES_LOAD_FAILURE';
interface ICardTypesLoadingAction {
  type: typeof CARD_TYPES_LOADING
  facilityId: number
}
interface ICardTypesLoadSuccessAction {
  type: typeof CARD_TYPES_LOAD_SUCCESS
  cardTypes: ICardTypeAvailable[]
}
interface ICardTypesLoadFailureAction {
  type: typeof CARD_TYPES_LOAD_FAILURE
  error: Error|IApiErrorResponse
}
type CardTypesLoadActionTypes = ICardTypesLoadingAction | ICardTypesLoadSuccessAction | ICardTypesLoadFailureAction;
export function loadAvailableCardTypes(facilityId: number): ThunkAction<Promise<ICardTypeAvailable[]>, IAppState, null, CardTypesLoadActionTypes> {
  return dispatch => {
    dispatch({ type: CARD_TYPES_LOADING, facilityId });

    return phoneCardResource
      .getAvailableCardTypes(facilityId)
      .then(res => {
        if (res == null) {
          throw new Error('Invalid response from server');
        }

        dispatch({ type: CARD_TYPES_LOAD_SUCCESS, cardTypes: res });
        return res;
      })
      .catch(error => {
        dispatch({ type: CARD_TYPES_LOAD_FAILURE, error: error.message });
        throw error;
      });
  }
}

export type CardTypeActions = (
  CardTypesLoadActionTypes |
  IResetAction |
  IResetSensitiveAction |
  PurchaseActions |
  ISetDetailsAction
)