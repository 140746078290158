import { IInmate } from '../models/Inmate';
import Api, { IPaginatedResponse } from '../services/api';

interface IIndexQuery {
  facilityId?: number
}

export function index(query: IIndexQuery) {
  return Api
    .get<IPaginatedResponse<IInmate>>({
      url: '/inmate',
      query: {
        ...query,
        page: 0
      }
    });
}

export function getByAni(ani: string) {
  return Api
    .get({
      url: '/text-message/inmate/by-ani/' + ani
    })
    .then((res: IInmate) => res);
}

export function getById(id: number) {
  return Api
    .get({
      url: '/inmate/by-id/' + id
    })
    .then((res: IInmate) => res);
}